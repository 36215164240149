import React from "react";
import { AppStore, StarRating4pt7, StarRating5 } from "app/components/Icon";
import { P2 } from "app/components/Typography";
import parentStyles from "../shared-styles.module.scss";
import styles from "./styles.module.scss";

export function AppReviews() {
  return (
    <div className={parentStyles.sectionContainer}>
      <h2 className={parentStyles.sectionHeader}>Hear from our users</h2>
      <div className={styles.sectionReviews}>
        <AppStore style={{ height: "35px", width: "35px" }} />
        <div className={styles.sectionReviewHeader}>
          <P2 style={{ fontWeight: "600" }}>App store reviews</P2>
          <StarRating4pt7 className={parentStyles.stars} />
        </div>
      </div>
      <div className={styles.reviewWrapper}>
        <div>
          <div className={styles.reviewContainer}>
            <h5 className={styles.reviewHeader}>
              Best money I&apos;ve spent in a longggg time!
            </h5>
            <StarRating5 className={parentStyles.stars} />
            Absolutely worth every penny! Clean user experience, Lots of
            content, motivational, Fun, helping me seriously lose weight,
            Experienced instructors, a motivated community and slightly
            addictive!
          </div>
        </div>
        <div>
          <div className={styles.reviewContainer}>
            <h5 className={styles.reviewHeader}>Use This to Your Advantage!</h5>
            <StarRating5 className={parentStyles.stars} />I really don&apos;t
            know why it took me so long to take my first class on this app
            however I finally did today and let me tell you that it was
            everything that I wish learning from YouTube would have been all
            these years. In the class was Chris Terry he was amazing! Like
            it&apos;s really a game changer for training at home.
          </div>
        </div>
      </div>
    </div>
  );
}
